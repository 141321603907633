import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'anchorCandle',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'anchorCandle',
    name: 'anchorCandle',
    config: {
      candleInterval: '3 minutes',
      valuePaths: [
        'item0.equity'
      ],
      trailAnchor: false,
      signal: [
        {
          leftOperand: '',
          operator: '',
          rightOperand: '',
          conditionOperator: 'and'
        }
      ],
    }
  },
  inputSchema: [
    {
      name: 'signal',
      type: pipeInputSchemaTypes.signal,
      label: 'Signal',
    },
    {
      name: 'trailAnchor',
      type: pipeInputSchemaTypes.select,
      label: 'Trail Anchor',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false }
      ]
    },
  ],
  searchTerms: [
    'anchorCandle'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'open',
      'high',
      'low',
      'close'
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};
export default configuration;
